import rocketlink from 'img/companyLogo/customerCards/rocketlink.png';
import databox from 'img/companyLogo/customerCards/databox.png';
import talentlyft from 'img/companyLogo/customerCards/talentlyft.png';
import livereacting from 'img/companyLogo/customerCards/liverecting.png';
import plaxonic from 'img/companyLogo/customerCards/plaxonic.svg';
import randomcoffee from 'img/companyLogo/customerCards/random-coffee.svg';

export const cards = [
  {
    title: '40% more paid subscriptions',
    desc: 'Improved onboarding, 110% increase in signups and reduced churn rates.',
    link: '/customers/rocketlink/',
    img: rocketlink,
    bgColor: '#f8f8f8',
  },
  {
    title: 'Increased conversions to paid by 223%',
    desc: 'The onboarding process streamlined by identyfying potential issues.',
    link: '/customers/databox/',
    img: databox,
    bgColor: '#f8f8f8',
  },
  {
    title: 'Increased trial to paid conversion by 25.2%',
    desc: 'Better understanding of your users always pays off.',
    link: '/customers/talentlyft/',
    img: talentlyft,
    bgColor: '#f8f8f8',
  },
  {
    title: 'Gained 25% savings of team worktime',
    desc: "The company's efficiency boosted, resulting in customer recognition.",
    link: '/customers/plaxonic/',
    img: plaxonic,
    bgColor: '#f8f8f8',
  },
  {
    title: "Improved customer support team's performance",
    desc: 'Solve user’s issues much faster with session replays.',
    link: '/customers/livereacting/',
    img: livereacting,
    bgColor: '#f8f8f8',
  },
  {
    title: 'Lowered the time needed to onboard new customers',
    desc: "Improving the website's UI lowered the number of customer support requests.",
    link: '/customers/randomcoffee/',
    img: randomcoffee,
    bgColor: '#f8f8f8',
  },
];
